#nav {
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: stretch;
  margin: 1em 0;
  padding: 0;
  font-size: 1.2em;
  display: flex;
}

#nav li:hover a, #nav li:hover span, #nav li.active a, #nav li.active span {
  color: #696969;
  background: #fff;
}

#nav li.current a {
  color: #fff;
  background: #444;
}

#nav li.special a {
  color: #fff;
  background: #44f;
}

#NavMenu {
  padding-left: 1.5em;
  line-height: 2.5em;
}

#NavMenu a {
  letter-spacing: .025em;
  color: #fff;
  border-radius: 1em .2em;
  width: 100%;
  height: 100%;
  padding: .5em 1em;
  font-weight: 800;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
}

#NavMenu {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

#NavMenu li {
  text-align: center;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  width: 105pt;
  padding: 0;
  display: flex;
}

#NavMenu li a {
  margin: .25em;
}

#logo {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#logo img {
  height: 48pt;
}

#logo.logo-landing img {
  height: 96pt;
}

@media (orientation: portrait) {
  #NavMenu {
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    width: auto;
  }

  #NavMenu li {
    width: 100%;
    line-height: 1.2em;
  }

  #logo img {
    height: 96pt;
  }

  #header-wrapper {
    margin: 0;
    padding: 0;
  }

  #language-select {
    width: 100%;
    height: 2em;
    display: block;
    top: 0;
    left: 1em;
  }
}

#language-select {
  display: block;
  position: absolute;
  top: 0;
  right: 1em;
}

#language-select a {
  letter-spacing: .025em;
  color: #696969;
  background: #fff;
  border-radius: .2em .2em 1em 1em;
  width: 100%;
  height: 100%;
  padding: .5em 1em;
  font-weight: 800;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
}

/*# sourceMappingURL=navbar.css.map */
