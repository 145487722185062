/* Nav */

#nav {
    flex-grow: 1;
    flex-shrink: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    padding: 0;
    margin: 0;
    /* position: absolute;
    right: 0;
    top: 0; */
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 1em;
}

#nav li:hover a, #nav li:hover span {
    background: #fff;
    color: #696969;
}

#nav li.active a, #nav li.active span {
    background: #fff;
    color: #696969;
}

#nav li.current a {
    background: #444;
    color: #fff;
}

#nav li.special a {
    background: #44f;
    color: #fff;
}

#NavMenu {
    line-height: 2.5em;
    padding-left: 1.5em;
}

#NavMenu a {
    transition: background-color .25s ease-in-out;
    font-weight: 800;
    letter-spacing: 0.025em;
    color: #ffffff;
    text-decoration: none;
    border-radius: 1em 0.2em 1em 0.2em;
    padding: 0.5em 1em 0.5em 1em;
    width: 100%;
    height: 100%;
}


#NavMenu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin: 0;
}
#NavMenu li {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    width: 105pt;
    padding: 0;
    text-align: center;
}

#NavMenu li a {
    margin: 0.25em;
}

#logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#logo img {
    height: 48pt;
}

#logo.logo-landing img {
    height: 96pt;
}

@media (orientation: portrait){
    #NavMenu {
        width: auto;
        flex-direction: column;
        flex-shrink: 0;
        flex-grow: 0;
    }
    #NavMenu li {
        width: 100%;
        line-height: 1.2em;
    }

    #logo img {
        height: 96pt;
    }
    #header-wrapper {
        padding: 0;
        margin: 0;
    }

    #language-select {
        display: block;
        top: 0;
        left: 1em;
        width: 100%;
        height: 2em;
    }
}

#language-select {
    display: block;
    position: absolute;
    right: 1em;
    top: 0em;
}

#language-select a {
    padding: 4px;
    transition: background-color .25s ease-in-out;
    font-weight: 800;
    letter-spacing: 0.025em;
    text-decoration: none;
    border-radius: 0.2em 0.2em 1em 1em;
    padding: 0.5em 1em 0.5em 1em;
    width: 100%;
    height: 100%;
    
    background: #fff;
    color: #696969;
}